import router from '@/router'
import { SigninDocument, SignUpDocument } from '@/generated/graphql'
import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'
import {
  CHECK_EXPIRED_JWT, LOGIN, LOGOUT, SIGNUP,
} from './action'
import {
  SET_TOKEN, SET_USER, CLEAR_ALL, UPDATE_USERS_ADDITIONAL, UPDATE_USERS_PASSWORD, UPDATE_USERS_STATUS, INSERT_SET_IP_BLOCK,
} from './mutation'

const AUTH_TOKEN_KEY = 'accessToken'
const AUTH_USER = 'userData'

export const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))

  return JSON.parse(jsonPayload)
}

const authStore = {
  namespaced: true,
  // TODO: init localstorage
  state: () => ({
    token: localStorage.getItem(AUTH_TOKEN_KEY),
    user: JSON.parse(localStorage.getItem(AUTH_USER)),
    passAuth: false,
    updateUsersPassword: [],
    updateUsersStatus: [],
    insertSetIpBlock: [],
  }),
  getters: {
    token: state => state.token,
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    passAuth: state => state.passAuth,
    updateUsersPassword: state => state.updateUsersPassword[0],
    updateUsersStatus: state => state.updateUsersStatus[0],
    insertSetIpBlock: state => state.insertSetIpBlock[0],
  },
  mutations: {
    [SET_TOKEN](state, token) {
      state.token = token
      localStorage.setItem(AUTH_TOKEN_KEY, token)
    },
    [SET_USER](state, user) {
      state.user = user
      localStorage.setItem(AUTH_USER, JSON.stringify(user))
    },
    [CLEAR_ALL](state) {
      state.token = ''
      localStorage.removeItem(AUTH_TOKEN_KEY)
      localStorage.removeItem(AUTH_USER)
      state.user = null
    },
    [UPDATE_USERS_PASSWORD](state, updateUsersPassword) {
      state.updateUsersPassword = updateUsersPassword
    },
    [UPDATE_USERS_STATUS](state, updateUsersStatus) {
      state.updateUsersStatus = updateUsersStatus
    },
    [INSERT_SET_IP_BLOCK](state, insertSetIpBlock) {
      state.insertSetIpBlock = insertSetIpBlock
    },
    passAuth(state, data) {
      state.passAuth = data
    },
    updateLocalStorage(state, user) {
      localStorage.setItem(AUTH_USER, JSON.stringify(user))
      state.user = user
    },
  },
  actions: {
    passAuth({ commit }, data) {
      // console.log('passAuth ::', data)
      commit('passAuth', data)
    },
    updateLocalStorage({ commit }, data) {
      commit('updateLocalStorage', data[0])
    },
    async [LOGIN]({ commit }, userData) {
      const { data } = await apolloClient.mutate({
        // mutation: SigninDocument,
        mutation: gql`
            mutation($site: String!, $userid: String!, $password: String!) {
            signin(site: $site, userid: $userid, password: $password){
              token
              user {
                id
                site
                userid
                username
                nickname
                recommendNickname
                email
                mobile
                cash
                point
                signinTotal
                betTotal
                depositTotal
                exchangeTotal
                rank
                role
                memo
                enrollIp
                enrollDate
                status
                password
                outcomePassword
                accountBank
                accountNumber
                updatedAt
                createdAt
              }
            }
          }`,
        variables: {
          site: userData.site,
          userid: userData.userid,
          password: userData.password,
        },
      })
      const { token, user } = data.signin

      /*
      - 토근 발행 대상 : User status 값이 ['active', 'additional'] 것만 부여
      */
      if (['active', 'additional', 'changePassword'].indexOf(data.signin.user.status) > -1) {
        // if(data.signin.user.status == "active") {
        commit(SET_TOKEN, token)
        await commit(SET_USER, parseJwt(token))
      } else {
        await commit(SET_USER, user)
      }
    },
    [LOGOUT]({ commit }) {
      // commit(CLEAR_ALL)
      router.replace('/login')
    },
    [CLEAR_ALL]({ commit }) {
      commit(CLEAR_ALL)
    },
    [CHECK_EXPIRED_JWT]({ dispatch, state }) {
      const expired = state.token ? parseJwt(state.token).exp < Date.now() / 1000 : false

      // 만료시 로그아웃 합니다.
      if (expired) dispatch(LOGOUT)
    },
    async [SIGNUP]({ commit }, payload) {
      let signupStatus = -99
      try {
        const { data } = await apolloClient.mutate({
          mutation: gql`
            mutation signup($data: SignupInput!) {
              signup(data: $data) {
                user {
                  userid
                }
              }
            }
          `,
          variables: {
            data: {
              ...payload,
              pId: `S00${Date.now()}`,
            },
          },
        })
        signupStatus = data.signup && data.signup.user ? 1 : 0
      } catch (error) {
        console.error('Error occurred during signup:', error)
        throw error
      }
      return signupStatus
    },

    async [UPDATE_USERS_ADDITIONAL]({ commit }, payload) {
      // console.log('[UPDATE_USERS_ADDITIONAL] payload : ', payload)
      return await apolloClient.mutate({
        mutation: gql`
          mutation updateUserAdditional($site: String, $userid: String, $username: String, $nickname: String, $recommendNickname: String, $email: String, $mobile: String, $cash: Float, $point: Float, $rank: Float, $role: String, $memo: String, $enrollIp: String, $enrollDate: Date, $status: String, $password: String, $outcomePassword: String, $accountBank: String, $accountNumber: String) {
            updateUserAdditional(site: $site, userid: $userid, username: $username, nickname: $nickname, recommendNickname: $recommendNickname, email: $email, mobile: $mobile, cash: $cash, point: $point, rank: $rank, role: $role, memo: $memo, enrollIp: $enrollIp, enrollDate: $enrollDate, status: $status, password: $password, outcomePassword: $outcomePassword, accountBank: $accountBank, accountNumber: $accountNumber) {
              userid
            }
          }
        `,
        variables: {
          ...payload,
        },
      })
    },

    async [UPDATE_USERS_PASSWORD]({ commit, state }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsersPassword($site: String, $userid: String, $password: String){
            updateUsersPassword(site: $site, userid: $userid, password: $password) {
              site
              userid
              password
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          password: payload.password,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USERS_PASSWORD, data.updateUsersPassword)
      // console.log('updateUsersPassword : ', data.updateUsersPassword)
    },

    async [UPDATE_USERS_STATUS]({ commit, state }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateUsersStatus($site: String, $userid: String, $status: String){
            updateUsersStatus(site: $site, userid: $userid, status: $status) {
              site
              userid
              status
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          status: payload.status,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_USERS_STATUS, data.updateUsersStatus)
      // console.log('updateUsersStatus : ', data.updateUsersStatus)
    },
    async [INSERT_SET_IP_BLOCK]({ commit, state }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertSetIpBlock($site: String, $ip: String, $type: String, $memo: String, $enrollId: String){
            insertSetIpBlock(site: $site, ip: $ip, type: $type, memo: $memo, enrollId: $enrollId) {
              site
              ip
              type
              memo
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          ip: payload.ip,
          type: payload.type,
          memo: payload.memo,
          enrollId: payload.enrollId,
        },
        fetchPolicy: 'no-cache',
      })
      commit(INSERT_SET_IP_BLOCK, data.insertSetIpBlock)
      console.log('insertSetIpBlock : ', data.insertSetIpBlock)
    },
  },
}

export default authStore

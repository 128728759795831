import { apolloClient } from '@/vue-apollo'
import { FETCH_USER_CASH } from '@/store/user/action'
import { } from '@/store/user/mutation'
import gql from 'graphql-tag'

const userStore = {
  namespaced: true,
  state: () => ({
    fetchUserCash: [],
  }),
  getters: {
    fetchUserCash: state => state.fetchUserCash[0],
  },
  mutations: {
    [FETCH_USER_CASH](state, fetchUserCash) {
      state.fetchUserCash = fetchUserCash
    },
  },
  actions: {
    async [FETCH_USER_CASH]({ commit }, payload) {
      // console.log('payload ::', payload)
      const { data } = await apolloClient.query({
        query: gql`
            query fetchUserCash($site: String, $userid: String) {
                fetchUserCash(site: $site, userid: $userid) {
                    id
                    site
                    userid
                    username
                    nickname
                    recommendNickname
                    email
                    mobile
                    cash
                    point
                    rank
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_USER_CASH, data.fetchUserCash)
      // console.log('FETCH_USER_CASH : ', data.fetchUserCash)
    },
  },
}

export default userStore

export const FETCH_SET_IP_BLOCK = 'FETCH_SET_IP_BLOCK'
export const FETCH_SET_DEPOSIT_EXCHANGE = 'FETCH_SET_DEPOSIT_EXCHANGE'
export const FETCH_GAME_SET_MENU = 'FETCH_GAME_SET_MENU'
export const FETCH_GAME_SET_ITEM = 'FETCH_GAME_SET_ITEM'
export const FETCH_GAME_SET_ODDS_CUSTOM = 'FETCH_GAME_SET_ODDS_CUSTOM'
export const FETCH_SET_EVENT = 'FETCH_SET_EVENT'
export const FETCH_GAME_SET_VENDOR = 'FETCH_GAME_SET_VENDOR'
export const FETCH_GAME_SLOT_VENDOR = 'FETCH_GAME_SLOT_VENDOR'
export const FETCH_GAME_SLOT_LIST = 'FETCH_GAME_SLOT_LIST'
export const FETCH_GAME_SLOT_START = 'FETCH_GAME_SLOT_START'
export const FETCH_GAME_CARD_LIST = 'FETCH_GAME_CARD_LIST'
export const FETCH_GAME_CARD_START = 'FETCH_GAME_CARD_START'

import { apolloClient } from '@/vue-apollo'
import { FETCH_EXCHANGE, FETCH_USERS_SMPL, FETCH_POINT_EXCHANGE } from '@/store/exchange/action'
import { INSERT_EXCHANGE, UPSERT_EXCHANGE, REQUEST_POINT_EXCHANGE } from '@/store/exchange/mutation'
import gql from 'graphql-tag'

const exchangeStore = {
  namespaced: true,
  state: () => ({
    exchange: [],
    fetchPointExchange: [],
    upsertExchange: [],
    insertExchange: [],
    fetchUsersSmpl: [],
  }),
  getters: {
    exchange: state => state.exchange,
    fetchPointExchange: state => state.fetchPointExchange,
    upsertExchange: state => state.upsertExchange,
    insertExchange: state => state.insertExchange,
    fetchUsersSmpl: state => state.fetchUsersSmpl,
  },
  mutations: {
    [FETCH_EXCHANGE](state, payload) {
      state.exchange = payload
    },
    [FETCH_POINT_EXCHANGE](state, payload) {
      state.fetchPointExchange = payload
    },
    [UPSERT_EXCHANGE](state, payload) {
      state.upsertExchange = payload
    },
    [INSERT_EXCHANGE](state, payload) {
      state.insertExchange = payload
    },
    [FETCH_USERS_SMPL](state, payload) {
      state.fetchUsersSmpl = payload
    },
  },
  actions: {
    async [FETCH_EXCHANGE]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchExchange($site: String, $userid: String) {
            fetchExchange(site: $site, userid: $userid) {
              idx
              site
              userid
              type
              exchange
              userBank
              userAccount
              adminBank
              adminAccount
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      // = this.FETCH_EXCHANGE(data.fetchExchange)
      commit(FETCH_EXCHANGE, data.fetchExchange)
      console.log('FETCH_EXCHANGE : ', data.fetchExchange)
    },
    async [INSERT_EXCHANGE]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const pId = `E00${Date.now()}`
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertExchange($insertExchangeArgs: InsertExchangeArgs!){
            insertExchange(insertExchangeArgs: $insertExchangeArgs) {
              site
              userid
              exchange
              type
              exchangeType
              userBank
              userAccount
              adminBank
              adminAccount
              paymentId
              userIp
              uid
            }
          }
        `,
        variables: {
          insertExchangeArgs: {
            site: payload.site,
            userid: payload.userid,
            exchange: payload.exchange,
            type: payload.type,
            exchangeType: payload.exchangeType,
            userBank: payload.userBank,
            userAccount: payload.userAccount,
            adminBank: payload.adminBank,
            adminAccount: payload.adminAccount,
            paymentId: pId,
            userIp: payload.userIp,
            uid: payload.uid,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(INSERT_EXCHANGE, data.insertExchange)
      console.log('INSERT_EXCHANGE : ', data.insertExchange)
    },
    async [FETCH_USERS_SMPL]({ state, commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchUsersSmpl($site: String, $userid: String) {
            fetchUsersSmpl(site: $site, userid: $userid) {
              site
              userid
              accountBank
              accountNumber
              username
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      // = this.FETCH_EXCHANGE(data.fetchExchange)
      commit(FETCH_USERS_SMPL, data.fetchUsersSmpl)
      // console.log('FETCH_USERS_SMPL : ', data.fetchUsersSmpl)
    },
    async [UPSERT_EXCHANGE]({ commit, state }, payload) {
      console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
            mutation upsertExchange($idx: Float, $site: String, $userid: String, $exchange: Float, $type: String, $userBank: String, $userAccount: String, $adminBank: String, $adminAccount: String, $uid: String){
                upsertExchange(idx: $idx, site: $site, userid: $userid, exchange: $exchange, type: $type, userBank: $userBank, userAccount: $userAccount, adminBank: $adminBank, adminAccount: $adminAccount, uid: $uid) {
                  idx
                  site
                  userid
                  type
                  exchange
                  userBank
                  userAccount
                  adminBank
                  adminAccount
                  uid
                }
            }
        `,
        variables: {
          idx: payload.idx,
          site: payload.site,
          userid: payload.userid,
          type: payload.type,
          exchange: payload.exchange,
          userBank: payload.userBank,
          userAccount: payload.userAccount,
          adminBank: payload.adminBank,
          adminAccount: payload.Account,
          uid: payload.uid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_EXCHANGE, data.upsertExchange)
      console.log('UPSERT_DEPOSIT : ', data.upsertExchange)
    },
    // 포인트 전환
    async [FETCH_POINT_EXCHANGE]({ commit, state }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchPointExchange($site: String, $userid: String, $periodFrom: String, $periodTo: String) {
            fetchPointExchange(site: $site, userid: $userid, periodFrom: $periodFrom, periodTo: $periodTo) {
              idx
              site
              category
              categoryDetail
              adminid
              adminIp
              targetUserid
              tran
              asisContent
              tobeContent
              asisVal
              tobeVal
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_POINT_EXCHANGE, data.fetchPointExchange)
    },
    async [REQUEST_POINT_EXCHANGE]({ commit, state }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation requestPointExchange($data: UsersAssetArgs!) {
            requestPointExchange(data: $data) 
          }
        `,
        variables: {
          data: {
            site: payload.site,
            userid: payload.userid,
            point: payload.point,
            userIp: payload.userIp,
          },
        },
        fetchPolicy: 'no-cache',
      })
      console.log('REQUEST_POINT_EXCHANGE : ', data.requestPointExchange)
    },
  },
}

export default exchangeStore
